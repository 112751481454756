import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Audio from "../../../components/audio/audio"
import hutAudio from "./hut-audio.mp3"
import gif from "./fictionGif.mp4"

const hutFiction = (
  <>
    <video
      autoPlay
      loop
      muted
      playsInline
      src={gif}
      style={{ width: "100%" }}
    ></video>
    <div className="u-side-pad u-bottom-pad">
      <h3 className="heading--big">Kaitawa</h3>
      <h4 className="heading--small">By Bernard Beckett</h4>
      <p>
        Everybody is afraid of something, and everybody believes their own fear
        is special. But it never is. Everybody’s fear, in the end, is exactly
        the same.
      </p>
      <p>
        Jasper’s mother was afraid that people would discover she could not
        read, and would laugh at her. Jasper’s older sister, Lucy, was afraid of
        growing to one day look like her Aunt Michelle, who moved through life
        with a permanent scowl on her face. Jasper’s father was afraid of
        unexpected bills. Jasper’s cat, Marshmallow, was afraid of lightning and
        fireworks (but not cars, which worried Jasper a great deal).
      </p>
      <p>
        Jasper’s fear was harder to name. It was more of a feeling than a thing.
        He was afraid of the feeling of night falling, of the darkness closing
        in a round him, just after his mother had told him his bed time story
        and tucked him in and kissed him good night. The light would click off
        and Jasper would feel the world change shape around him. Sounds he never
        heard in the daylight would creep into his mind. Quiet whispers in the
        trees: unseen forces secretly plotting and making plans. There was a
        snuffling sound beneath his window: some dark creature of the night
        waiting patiently for the boy within to fall asleep. Jasper’s father had
        investigated this sound on three separate occasions and proclaimed it to
        be ‘just a hedgehog.’ Adults are good at sounding certain, even when
        they don’t know the answer. Most of the time, adults don’t even know
        what the question is. Something else frightened Jasper when night fell,
        a feeling in his chest, as if the air was growing thinner and harder to
        breathe, as if some great invisible weight was pressing down on him.
      </p>
      <p>
        He tried to explain these things to his parents, and they always would
        listen and nod, and patiently turn the light back on and pretend to
        check behind the curtains. Then they would offer him a drink of water.
        As if you could get rid of the fears inside your head by simply drowning
        them. It didn’t make Jasper any less afraid. It just made him want to
        pee. It did teach him something important though: you can’t always rely
        on grown-ups.
      </p>
      <p>
        So Jasper turned to technology. He ‘borrowed’ his sister’s phone and set
        it up on his pillow, with the camera facing the window. Before he fell
        asleep he pressed ‘video’, certain he would finally get incontrovertible
        evidence of the unworldly creature that was visiting him each night. But
        he rolled in his sleep and knocked the phone to the floor, where it
        captured a long and uneventful close-up of the carpet weave. You can’t
        always rely on technology.
      </p>
      <p>
        Perhaps it might have ended there. He might have grown used to the
        fears, or grown out of them, the way some people suggested he would.
        Perhaps those were the same thing. But then he had the strangest dream.
        Admittedly, dreams are mostly strange, animals speak, time jumps and
        stretches, people fly and run as fast as they can without moving at all.
        But this dream was strange for a different reason. When Jasper woke the
        next morning he could remember every last detail. It was almost like,
        just by closing his eyes, he could replay the entire dream back in his
        head. Like YouTube, but without the ads.
      </p>
      <p>
        In the dream, he heard a noise in the garden and walked out the sliding
        door in his bedroom and stood in the complete darkness of the back lawn,
        somehow completely unafraid. A small fuzzy light, the size of a tennis
        ball, hovered before his head, and it spoke to him. And here is what it
        said. Exactly. Word for word.
      </p>
      <p>
        <em>
          Jasper, I know that every night you are frightened. And I can help
          you. There is a place you know, and in it there is a place you do not
          know. You must walk to your favourite flying fox early in the morning,
          just before the darkness has broken, and look for my light. That is
          all you need to do to understand your fear. Be brave.
        </em>
      </p>
      <p>
        Jasper went over the words again and again, tripping each time in
        exactly the same places, feeling the same sense of hope and dread,
        stretching him out, pulling him in opposite directions. ‘In it there is
        a place you do not know.’ That felt like a riddle. And in Jasper’s
        experience, the things most often hidden in riddles were tricks. ‘Just
        before the darkness has broken.’ That was the thing that caused him most
        worry. The flying fox was easy, that meant Kaitawa Reserve, but it was
        ten minutes scoot from his house, and that was in the daylight. But
        before the darkness has broken? How would he even be awake then? And
        finally, every time he thought of it, he came back to the same single
        word. ‘Understand.’ What did that mean, that he would understand his
        fear? Perhaps that would be a good thing, but perhaps it wouldn’t. It
        seemed possible to Jasper, the more he thought about it, that visiting
        Kaitawa Reserve was a very bad idea indeed. But that didn’t mean he
        could stop thinking about it.
      </p>
      <p>
        The next night the fear was as bad as it had ever been, and the night
        after that it was even worse. He still couldn’t decide whether to follow
        the message in the dream and so he decided he would leave the whole
        thing to fate. So, before he went to bed, he wheeled his scooter out of
        the garage and hid it at the side of the house, and he took a long drink
        of water from the largest glass he could find. He remembered hearing
        that in the days before alarm clocks, soldiers would make themselves
        wake early by drinking water before they went to sleep. He didn’t know
        whether this would work or not, and that was the point. If the water
        woke him, then he would follow the dream. He was leaving the decision up
        to his bladder.
      </p>
      <p>
        It worked. Jasper woke in darkness, dressed in the clothes he had hidden
        under his bed, scooted silently out onto the footpath and had the
        longest, most satisfying pee on his neighbour’s rose bush. He checked
        his torch was still in his jacket pocket, along with a chocolate bar
        he’d stolen from his father’s not-so-secret stash and his pocket knife,
        and pushed himself onwards, on a collision course with fear.
      </p>
      <p>
        By daylight Kaitawa Reserve is a place of happiness. There is a
        children’s playground and flying fox that whizzes across a stream, a
        stream that gurgles and splashes its way across small sharp stones, and
        is exactly the right amount of overgrown for small and intrepid
        explorers. The grass is long and tickles when you chase through it, and
        the bush spills down from the hills, making a secret hideaway of every
        twist and turn. It is a place of squealing laughter and the sorts of
        scratches that sting but do not require a bandaid. Of parents on
        cellphones, absent mindedly promising their children ice creams later,
        if they play kindly with one another, and school groups with hats and
        sunscreens and buddies for the walk back to class.{" "}
      </p>
      <p>
        At night, just before the darkness breaks, Kaitawa Reserve is nothing
        like that. The details smudge, one into the other. The darkness is deep
        and forbidding. Twigs break underfoot with a sound like gunshot, that
        for a moment stops the heart. Branches stroke your face without warning
        or apology. The land becomes rutted and unpredictable. There is no left,
        no right. No up or down. The further Jasper moved from the last
        streetlight, obscured by the thick foliage of paired pohutukawa,
        flashing a last distress signal in the wind, the more impenetrable the
        shadows become. Jasper pressed on. He had known it would be frightening.
        And he’d promised himself he would move forward.
      </p>
      <p>
        He knew the area well. He was a local and he often played there, and
        once he had calmed down enough to think clearly, he realised the stream
        was the key. While the other sounds of the night were indistinct and
        unreadable: the stop start noise of distant traffic, the rumbling
        thunder of an overnight freight train, the cooling wind shuffling the
        leaves over head; the stream was speaking to him. Over here, it said.
        This way. I am still here. Even in the night time, I am here.
      </p>
      <p>
        Jasper did not turn on his torch. Not yet. It would make it easier to
        see, but it would make him easier to be seen, too. And he wasn’t sure
        that was the best idea. He walked carefully down the slope towards the
        sound of the water, imagining exactly where he was with every footstep.
        Believing. <em>Here</em>, he told himself, the water is close.{" "}
        <em>And there</em>, he waved his hand overhead into the darkness.
        <em>There is where the wire of the flying fox runs</em>.
      </p>
      <p>
        His foot slipped down a bank and he felt the cool of the stream seep
        into the toe of his sneakers. He had made it. Now he could turn on the
        torch. If he kept it pointing low, at the water, it would not be visible
        from the road.{" "}
      </p>
      <p>
        After the inky black of night, the light of his torch was brash and
        piercing. The water beneath him glowed white, illuminating a fat eel
        lowly twisting its way up the current. Jasper edged his foot out of the
        stream and watched dark shadow move confidently back into the depths and
        disappear, untroubled. He wished he felt the same.
      </p>
      <p>
        <em>
          There is a place you know. And in it there is a place you do not know.
        </em>{" "}
        So Jasper was looking for something unfamiliar, but he did not know what
        it was. Perhaps it was the eel. Perhaps it was the whole place. At
        night, all of it looked unfamiliar. He turned slowly around, a 360
        degree rotation, looking and listening for something, anything, that
        might be a clue.
      </p>
      <p>
        There came a humming sound, so low that at first he thought he was
        imagining it. When he turned upstream, to the place where he thought the
        sound was coming from, he saw a light. A small fuzzy light, the size of
        a tennis ball. Familiar. It moved slowly away from him, following the
        course of the path, then hovering, as if waiting for Jasper to follow.{" "}
      </p>
      <p>
        Jasper moved after it. His body felt strange and mechanical, as if this
        too was a dream. The path was smooth underfoot, and what he had first
        thought was his eyes adjusting to the darkness was, he now realised, the
        very first of the day’s light leaking into the eastern sky. He could see
        shapes now, make out the trunks of trees. He switched off his light and
        stumbled forward.
      </p>
      <p>
        Again the light stopped, swaying slightly from side to side, as if
        impatient. Jasper moved closer and, just when he was almost close enough
        to touch it, it ducked off the path and into the bush, like a child
        playing chase. An irritating, teasing, faster-than-you, child. Jasper
        had barely travelled ten metres from the path when he saw it. Here, in
        the place he knew so well, a place he did not know at all.{" "}
      </p>
      <p>
        A strange sculpture, or perhaps a shelter of some kind, a nest even,
        Jasper had no proper word for it. It looked like no thing he had ever
        seen before and yet, at the same time, it looked as if belonged here. As
        if there was no other place it could belong. Yes, shelter was the best
        description, Jasper decided, drawing closer. Its bottom was a kind of
        large bowl, woven together from long thin branches, carefully shaped and
        yet haphazard. Its roof was even more perplexing, a tarpaulin stretched
        across the frame, supplemented by offshoots of wood and metal, each
        piece random and yet still fitted perfectly into the greater shape.{" "}
        <em>A contradiction</em>, Jasper thought. He liked naming things with
        unusual words.{" "}
        <em>
          If I were to call this something, I would call it a contradiction.
        </em>
      </p>
      <p>
        Jasper moved forward. He still did not know what it was he was doing
        here. Was there a way into the structure, and would he dare enter it? He
        reached out and touched one of the switches, half expecting his hand to
        pass right through, but it was as solid as any piece of wood might be,
        its bark smooth and night-cool. He felt bolder now that he was sure it
        was a solid thing. He moved to his left, looking for an entrance.{" "}
      </p>
      <p>
        The sound was unmistakable, the snapping of a twig in the bush directly
        behind him, followed by a single heavy footfall, a person stepping back
        from the sound they had made, standing still and silent, listening for a
        sign they’d been heard.{" "}
      </p>
      <p>
        There are moments where we are called upon to be brave, or maybe foolish
        is a better word; often they are the same thing. Jasper moved quietly
        back around the structure, his breath held, his eyes straining to take
        in the details of the dawning world. He stopped, listened, imagined a
        person on the opposite side of the magical shelter, doing the same. He
        turned, scanned the space behind him, crept backwards… one step, two…
        collided. It was a young boy, about his size and age, no one he
        recognised from school. In their panic the two of them became entangled,
        twisting together and falling noisily to the ground. As if that wasn’t
        frightening enough, somewhere nearby, a third child screamed, and a
        fourth shot out from the bushes and set running up the park, as fast as
        her small legs would take her.
      </p>
      <p>
        ‘Sorry,’ Jasper muttered. In times of danger he reverted to politeness,
        even though he understood that wasn’t always the best survival strategy.
      </p>
      <p>‘For what?’ the other boy answered. ‘And who screamed?’</p>
      <p>
        ‘Sorry, that was me.’ A girl, their age too, as best Jasper could tell,
        stood up from her hiding place, crouched beside the structure.
      </p>
      <p>
        Jasper got back up and offered her his hand, as he had seen adults do
        with strangers. ‘My name is Jasper.’
      </p>
      <p>‘Annika.’</p>
      <p>
        ‘I’m Troy.’ The boy stood and smiled and then looked quickly to the
        ground. Silence filled the gaps. They heard the fourth child, edging her
        way back to the group.
      </p>
      <p>
        ‘Hi,’ she emerged from the trees and gave an apologetic half wave. ‘I’m
        Maia. You frightened me.’
      </p>
      <p>‘I think we all frightened each other,’ Jasper answered.</p>
      <p>
        ‘And, what are you doing here?’ Annika asked. ‘What brought you here?’
      </p>
      <p>
        It was a question for all of them and Jasper, like the others, hoped
        somebody else would answer. In the end it was Troy who was bravest.
      </p>
      <p>‘It’s stupid,’ he said. ‘You won’t believe me.’</p>
      <p>‘We might,’ Jasper urged, but the boy shook his head.</p>
      <p>‘Nah. I had a dream.’</p>
      <p>‘About a ball of light?’ Annika asked. Troy looked at her startled.</p>
      <p>‘How did you know?’</p>
      <p>‘And it spoke to you,’ Maia added.</p>
      <p>
        ‘It said that at this place you know,’ Jasper said, keen now to share
        his credentials...
      </p>
      <p>‘There is a place you do not know,’ Troy muttered.</p>
      <p>
        ‘A place to understand your fear,’ Maia offered. She looked around at
        the others, apparently unable to quite believe any of this was
        happening. Jasper knew how she felt. They all did.
      </p>
      <p>
        ‘What are you afraid of?’ Jasper asked. The words snagged in his dry
        throat and he had to cough to get them out.
      </p>
      <p>
        ‘Of getting lost in a crowd,’ Annika told them. Jasper could see how
        foolish she felt, saying it. Fears always sound foolish, when spoken out
        loud.
      </p>
      <p>
        ‘Of having my friends find out I take ballet lessons,’ Troy said,
        without being asked.
      </p>
      <p>
        ‘That my parents will stop loving each other,’ Maia said, with a shrug
        too small to dislodge the weight of it.
      </p>
      <p>
        ‘I am frightened of the dark,’ Jasper said. He’d never put it like that
        before, and now that it did, it felt smaller somehow, less terrifying.
      </p>
      <p>
        ‘So why are we here?’ Maia’s question was the obvious one, and Jasper
        had no good answer.
      </p>
      <p>
        ‘There is a book,’ Annika said. ‘Inside the shelter. On its cover it
        just says <em>Kaitawa</em>. I wasn’t brave enough to open it.’
      </p>
      <p>
        They knew what came next, even if none of them was courageous enough to
        suggest it. In the end it was decided with a simple nod, from Troy to
        Annika, who moved quietly to the other side of the structure. The others
        followed without discussion.{" "}
      </p>
      <p>
        There was barely room for the four of them inside and they sat close,
        Annika in the centre, the book held closed in her shaking hands. Jasper
        noticed how good it felt, to be this close to other people. Warm and
        comforting. The opposite of fear.{" "}
      </p>
      <p>‘Open it,’ Jasper whispered.</p>
      <p>
        Maia took his hand and gave it a small squeeze. Jasper placed his arm
        around Troy’s lightly shivering shoulders. They moved closer still.
        Their breath mingled.
      </p>
      <p>
        Annika opened the book and the mouths of four children moved silently as
        they read together the first hopeful lines:
      </p>
      <p>
        <em>
          Everybody is afraid of something, and everybody believes their own
          fear is special. But it never is. Everybody’s fear, in the end, is
          exactly the same.
        </em>
      </p>
      <p>
        <OutboundLink
          href="https://drive.google.com/open?id=1ehrlkbUOs1hYQ-AiPl3Fk3K9r9lXm-NR"
          target="_blank"
          rel="noopener noreferrer"
          className="big-link"
        >
          Download ‘Kaitawa’ by Bernard Beckett
        </OutboundLink>
      </p>

      <Audio audio={hutAudio} />
    </div>
    <div className="about-the-author u-side-pad">
      <h3 className="heading--small about-the-author__heading">
        About the author
      </h3>
      <p>
        Bernard Beckett is a father, school teacher, and writer. He has three
        boys and has worked in secondary schools in the Wellington region for
        thirty years and currently teaches drama at Hutt Valley High School. He
        has written a number of novels and plays over the years and all going
        well will write a few more before his time expires.
      </p>
    </div>
  </>
)

export default hutFiction
