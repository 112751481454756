import React, { Fragment } from "react"
import Layout from "../../../components/layout/layout"
import directionsContent from "../../../components/hut-data/hut3/directions"
import hutFictionContent from "../../../components/hut-data/hut3/hutFiction"
import aboutContent from "../../../components/hut-data/hut3/about"
import HutPageLayout from "../../../components/hutPageLayout/hutPageLayout"
import HutMap from "../../../components/hutMap/hutMap"
import { AccordionContent } from "../../../components/accordion/accordion"
import { GeolocationContext } from "../../../AppState"
import hutTitle from "../../../components/hut-data/hut3/hut-title.svg"
import doodle from "../../../components/hut-data/hut3/hut-doodle.jpg"
import aboutImg from "../../../components/hut-data/hut3/about-img.jpg"

const accordionContent: AccordionContent[] = [
  {
    title: "Directions",
    inner: (
      <Fragment>
        <img src={doodle} className="width-full" alt="" />
        <div className="u-side-pad u-top-pad">{directionsContent}</div>
      </Fragment>
    ),
    backgroundColour: "#fae297",
  },
  {
    title: "Found it",
    inner: "",
    backgroundColour: "#ffcc99",
  },
  {
    title: "Hut fiction",
    inner: hutFictionContent,
    backgroundColour: "#d0dce2",
    locked: true,
  },
  {
    title: "About",
    inner: (
      <Fragment>
        <img src={aboutImg} alt="" className="width-full" />
        {aboutContent}
      </Fragment>
    ),
  },
]

function Hut() {
  return (
    <Layout title="Kaitawa Hut">
      <GeolocationContext.Consumer>
        {context => {
          const { foundHuts } = context
          return (
            <div className="map__info-container">
              <HutMap
                isHutPage
                height="320px"
                foundHuts={foundHuts}
                bounds={[
                  [175.00018789688454, -40.91871300111657],
                  [175.01528495224318, -40.9270061789941],
                ]}
              />
            </div>
          )
        }}
      </GeolocationContext.Consumer>
      <HutPageLayout
        hutId="hut3"
        heading="Kaitawa Hut"
        headingImg={hutTitle}
        headingColour="#339933"
        description="Like baby creatures of the bush we need protection from the elements. If humans made nests would they look like this?"
        accordionContent={accordionContent}
        unlockCode="NE5TING"
      />
    </Layout>
  )
}

export default Hut
