import React, { Fragment } from "react"

const directions = (
  <Fragment>
    <h3>Start Location</h3>
    <p>Kaitawa Reserve entrance, between 22 &amp; 26 Kaitawa Cres.</p>
    <h3>Distance to Hut</h3>
    <p>400m (75m via more accessible route)</p>
    <h3>Your Directions</h3>
    <p>
      Follow sealed track past the playground and flying fox to the bridge
      through the bush. Head down the steps to cross the bridge. Keep following
      the track to the next steps and bridge and cross that too. Continue
      following the path until you see the outdoor classroom sign for Mamakū on
      your left above a big waterway culvert. Being careful not to let anyone
      get too close to this sharp drop off, head off track here through the
      trees. You will see a rough path heading towards the right, follow this
      until you come to a small clearing beneath Kahikatea trees. The hut sits
      facing the stream somewhere to your left.
    </p>
    <h3>Nearest Train Station</h3>
    <p>800m from Paraparaumu Station</p>
    <h3>Safety Notes</h3>
    <p>
      The hut is very close to a stream with some short drop offs. Watch kids
      closely and while you may decide it’s safe to have a paddle don’t drink
      the water (the stream runs from a spring and doesn’t encounter too much
      agriculture on its way to Kaitawa). There may be a bumblebee nest nearby
      and there are definitely mozzies so bee bug safe.
    </p>
    <p>
      Having said that, this is the best hut to take pre-school age kids. The
      playground is fairly new and the bridges are heaps of fun to cross. Plenty
      of shade throughout the park but being sun safe never hurts. You may come
      across some fallen obstacles in the undergrowth around the hut, if they
      are of the man-made variety be a tidy kiwi and discard in park bins – we
      all keep our kids safer together.
    </p>
    <h3>Accessibility</h3>
    <p>
      This hut is wheelchair accessible with assistance if you are willing to be
      adventurous and have someone who can join you on your journey. Here is the
      alternative route as the main directions take you via bridges with stairs.
    </p>
    <p>
      Enter Kaitawa Reserve from the Riwai St entrance (between 44 & 50, on the
      right hand side of the road as you enter the street (north side)). Follow
      the main path (hard gravel, one slightly raised manhole to cross) until
      you see the Mamaku sign on your right. Being careful not to get too close
      to the sharp drop off by the sign, head off track through the trees. It
      will be a bumpy ride, there are some big roots and dips so you will need
      someone to maneuver the wheelchair. You will see a rough path heading
      towards the right, follow this until you come to a small clearing beneath
      Kahikatea trees. The hut sits facing the stream somewhere to your left. As
      you approach the front of the hut be aware of the sloping bank towards the
      stream.
    </p>
  </Fragment>
)

export default directions
